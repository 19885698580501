<template>
  <b-card>
    <div class="searh-buyer d-flex justify-content-between">
      <b-button :variant="purchased == true ? 'success' : 'danger'" @click="purchasedF" class="mb-1">
      <feather-icon :icon="purchased== true ? 'CheckIcon' : 'XIcon'"></feather-icon>
      {{ purchased == true ? "Onaylanmış/Bekleyen" : "Tümü" }}
    </b-button>
    <!-- searchbar for buyer.id.name -->
    <div class="search-buyer-box d-flex align-items-center">
      <b-form-group  label="Üye Ara" label-for="search-input" class="">
        <b-form-input id="search-input" v-model="searchBuyer" type="search" placeholder="Üye Ara" />
      </b-form-group> 
      <b-button variant="primary" @click="searchBuyerF" class="ml-1 h-50">
        <feather-icon icon="SearchIcon" />
      </b-button>
    </div>
    </div>
    
    <b-table ref="sessionTable" class="position-relative" :items="sessionList" responsive :fields="tableColumns"
      show-empty empty-text="Satınalım Bulunamadı">
      <!-- <template #cell(title)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.title }}</span>
        </div>
      </template>
      <template #cell(ip)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ IPPARSER(data.item.ip) }}</span>
        </div>
      </template>
      <template #cell(actions)="data">
        <b-button variant="flat" class="text-danger" @click="disconnectSession(data.item._id)">
          <feather-icon icon="XIcon" size="18" />
        </b-button>
      </template> -->
      <template #cell(buyer.id.name)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.buyer.id.name
            }}</span>
        </div>
      </template>
      <template #cell(price)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ currencyParser(data.item.price)
            }}</span>
        </div>
      </template>
      <template #cell(paidPrice)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ currencyParser(data.item.paidPrice)
            }}</span>
        </div>
      </template>
      <template #cell(updatedAt)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ new Date(data.item.updatedAt).toLocaleString("tr-TR", {
                day: "2-digit",
                month: "long",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            }}</span>
        </div>
      </template>
      <template #cell(status)="data">
        <div class="text-nowrap">
          <span v-if="data.item.status == 'success'">
            <FeatherIcon icon="CheckIcon" class="text-success" size="18" />
          </span>
          <span v-else-if="data.item.status == 'pending'">
            <FeatherIcon icon="ClockIcon" class="text-warning" size="18" />
          </span>
          <span v-else>
            <FeatherIcon icon="XIcon" class="text-danger" size="18" />
          </span>
        </div>
      </template>
      <div slot="empty" class="text-center">
        <span class="align-middle">{{ $t("No Records Found") }}</span>
      </div>
    </b-table>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BAvatar,
  BTable,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
const {
  axiosRoutes: { payment },
} = require("/G_CONFIG");

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BAvatar,
    BTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currencyParser: new Intl.NumberFormat("tr-TR", {
        style: "currency",
        currency: "TRY",
      }).format,

      tableColumns: [
        { key: "buyer.id.name", label: "Alan", sortable: true },
        { key: "purchaseAmount", label: "Adet", sortable: true },
        { key: "price", label: "Fiyat", sortable: true },
        { key: "paidPrice", label: "Ödenen", sortable: true },
        // { key: "createdAt", label: "Oluşturulma Tarihi", sortable: true },
        { key: "updatedAt", label: "Tarih", sortable: true },
        { key: "status", label: "Durum", sortable: true },
      ],
      sessionList: [
        {
          // actions: "Actions",
        },
      ],
      purchased: false,
      searchBuyer: "",
    };
  },
  created() {
    this.getPayments();
    // axios.get(sessionPath.get).then((res) => {
    //   this.sessionList = res.data;
    //   // console.log("object :>> ", this.sessionList);
    // });
  },
  methods: {
    // disconnectSession(id) {
    //   axios.delete(sessionPath.delete + "/" + id).then((res) => {
    //     this.getSessions();
    //     // console.log("object :>> ", this.sessionList);
    //   });
    // },
    getPayments() {
      axios.get(payment.getAll).then((res) => {
        console.log("res :>> ", res);
        this.sessionList = res.data;
        // console.log("object :>> ", this.sessionList);
      });
    },
    purchasedF(){
      this.purchased = !this.purchased;
      if (this.purchased == true) {
        this.sessionList = this.sessionList.filter((item) => item.status == "success" || item.status == "pending");
      }
      else{
        this.getPayments();
        
      }
    },
    searchBuyerF(){
      if (this.searchBuyer != "") {
        this.sessionList = this.sessionList.filter((item) => item.buyer.id.name.toLowerCase().includes(this.searchBuyer.toLowerCase()));

      }
      else{
        this.getPayments();
        this.purchased = false;
      }
    },
   
    // IPPARSER(ip) {
    //   // return ip.replace("::ffff:", "");
    //   return ip;
    // },
  },
  
};
</script>
